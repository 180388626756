import * as React from 'react'
import {HashLink as Link} from 'react-router-hash-link'
import './MenuItem.css'

const MenuItem = ({ name, link, hash, mobile, handleClick }: iMenuItemProps) => {
  return (
    <li className="MenuItem">
      {mobile ? (
          <Link
            className="MenuItem__link"
            title={name}
            to={{
              pathname: link,
              hash
            }}
            onClick={() => handleClick()}
          >
            {name}
          </Link>
      ) : (
        <Link
          className="MenuItem__link"
          title={name}
          to={{
            pathname: link,
            hash
          }}
        >
          {name}
        </Link>
      ) }
    </li>
  )
}

interface iMenuItemProps {
  name: string
  link: string
  hash: string,
  mobile: boolean
  handleClick: () => void
}

export default MenuItem
