import React from "react";
import Background from "../Components/Background/Background";
import Menu from "../Components/Menu/Menu";
import Footer from "../Components/Footer/Footer";
import {useParams} from "react-router-dom";
import {GalleryList} from "../Components/Gallery/GalleryList/GalleryList";
import GalleryComponent from "../Components/Gallery/Gallery";
import {config} from "../config";
import {Development} from "../Components/Development/Development";

function Gallery() {
    const {id} = useParams()

    return (
        <>
            <div className="container-fluid bg-dark text-white main__container">
                <Background />
                <header style={{minHeight: 'unset'}}>
                    <Menu />
                </header>
                <main style={{position: 'relative', display: 'flex', justifyContent: "center", alignItems: 'center', minHeight: '100vh', zIndex: 10}}>
                    {id ? <GalleryComponent isMainPage={false} galleryId={id}/>: <GalleryList />}
                </main>
                <Footer />
                {config.buildType === 'development' ? <Development /> : <></>}
            </div>
        </>
    );
}

export default Gallery;
