import React, {FC, useEffect, useState} from 'react'
import { config } from '../../config'
import { makeRequest } from '../../utils/makeRequest'
import Error from '../Error/Error'
import Loading from '../Loading/Loading'
import Image from './Image/Image'
import './Gallery.css'
import FullscreenGallery from './FullscreenGallery/FullscreenGallery'
import { ApiVersion } from '../../config/types'
import getUrl from "../../utils/getUrl";
import {VrImage} from "./VrImage/VrImage";

interface GalleryComponentProps {
  isMainPage: boolean
  galleryId?: string
}

interface ImageData {
  id: number,
  link: string,
  galleryId: number,
  deleted: boolean
}

interface GalleryData {
  id: number
  name: string,
  galleryType: 'vr' | 'normal'
}

const GalleryComponent: FC<GalleryComponentProps> = ({isMainPage, galleryId}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [imgs, setImgs] = useState<string[]>([])
  const [fullscreen, setFullscreen] = useState(false)
  const [fullscreenImg, setFullscreenImg] = useState('')
  const [galleryData, setGalleryData] = useState<Partial<GalleryData>>()

  useEffect(() => {
    //TODO: it could be simplified
    if(isMainPage) {
      makeRequest('image/getMainPage', {
        method: 'GET',
        Api: ApiVersion.v1,
        headers: {},
      })
          .then((response: {data: ImageData[], status: number}) => {
            const { data } = response

            const _imgs: string[] = []
            data.forEach(img => {
              _imgs.push(`${getUrl()}${img.link}`)
            })
            setImgs(_imgs)
            setLoading(false)
          })
          .catch((err) => {
            if (config.buildType === 'development') {
              console.log(err)
            }

            setLoading(false)
            setError('Error while loading gallery')
          })
    } else if(!isMainPage && galleryId) {
      makeRequest(`image/getImagesInGallery?galleryId=${galleryId}`, {
        method: 'GET',
        Api: ApiVersion.v1,
      })
          .then((response: {data: ImageData[], status: number}) => {

            const { data } = response

            const _imgs: string[] = []
            data.forEach(img => {
              _imgs.push(`${getUrl()}${img.link}`)
            })

            setImgs(_imgs)

              makeRequest(`gallery/getGallery?id=${galleryId}`).then((galleryResponse: {data: GalleryData[], status: number}) => {
                setGalleryData({name: galleryResponse.data[0].name, galleryType: galleryResponse.data[0].galleryType})
                setLoading(false)
              }).catch(error => {
                setLoading(false)
                setError('Error while loading gallery')
              })

            // setLoading(false)
          })
          .catch((err) => {
            if (config.buildType === 'development') {
              console.log(err)
            }

            setLoading(false)
            setError('Error while loading gallery')
          })
    }
  }, [])

  const toggleFullscreenGallery = (imgUrl: string) => {
    setFullscreen(true)
    setFullscreenImg(imgUrl)
  }

  const closeFullscreen = () => {
    setFullscreenImg('')
    //FIXME: Get rid of setTimeout
    setTimeout(() =>  setFullscreen(false), 1)
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      {error ? (
        <Error message={error} />
      ) : (
        <>
          {fullscreen ? (
            <FullscreenGallery
              imgs={imgs}
              currentImage={fullscreenImg}
              handleClose={() => closeFullscreen()}
            />
          ) : (
            <></>
          )}
          <div className={isMainPage ? 'Gallery__container' : 'Gallery__container Gallery--galleryPage'}>
            {!isMainPage ? <h1>{galleryData?.name}</h1> : <></>}
            {galleryData?.galleryType === 'vr' ?
                <div className="Gallery__vr-images__wrapper">
                  {imgs.map((img, index) => (
                      <VrImage key={index} url={img}/>
                  ))}
                </div>
                :
                <div id="gallery" className="Gallery__images__wrapper">
                  {imgs.map((img, index) => (
                    <Image
                        key={index}
                        imageUrl={img}
                        imgClick={(imgUrl: string) => toggleFullscreenGallery(imgUrl)}
                        id={index}
                    />
                  ))}
                </div>
            }
          </div>
        </>
      )}
    </>
  )
}

export default GalleryComponent
