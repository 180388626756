import useAuth from "../../Hooks/useAuthenticate";
import {FormEvent, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {AddGalleryModal} from "../../Components/AddGalleryModal/AddGalleryModal";
import {iGallery} from "../../@types/apiDataTypes";
import {config} from "../../config";
import {makeRequest} from "../../utils/makeRequest";
import {ApiVersion} from "../../config/types";
import getUrl from "../../utils/getUrl";

export const AdminMain = () => {
    const {user} = useAuth()
    const navigate = useNavigate()

    const galleryId = useRef<HTMLSelectElement>()
    const files = useRef<HTMLInputElement>()

    const [galleries, setGalleries] = useState<Partial<iGallery>[]>([])
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        async function getData() {
            const response = await makeRequest('gallery/getAll', {method: "GET"})

            setGalleries(response.data)
        }

        if(!user || !user.authenticated) {
            navigate('/admin/login')
        } else {
            getData()
        }
    }, [])

    const submit = async (e: FormEvent) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('galleryId', galleryId.current.value)

        for(let i = 0; i < files.current.files.length; i++) {
            formData.append('imgs[]', files.current.files.item(i))
        }

        //TODO: Change to makrequest
        const response = await fetch(`${getUrl()}/api/v1/index.php/image/addImages`, {method: "POST", body: formData})

        if(config.buildType === 'development') {
            console.log(await response.text())
        }
    }

    return <>
    <Form onSubmit={(e) => submit(e)}>
        <Form.Group>
            <Form.Label>Gallery</Form.Label>
            <div className='d-flex'>
            <Form.Select ref={galleryId}>
                {galleries.map(gallery => (
                    <option value={gallery.id}>{gallery.name}</option>
                ))}
            </Form.Select>
            <Button variant="primary" type="button" onClick={() => setModalShow(true)}>+</Button>
            </div>
        </Form.Group>
        <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label>Images (multiple files)</Form.Label>
            <Form.Control ref={files} type="file" multiple />
        </Form.Group>
        <Button type="submit" variant="primary">Add</Button>
    </Form>
    <AddGalleryModal show={modalShow} onHide={() => setModalShow(false)} onAdded={(gallery) => {
        const updated = galleries
        updated.push(gallery)
        setGalleries(updated)
    }}/>
    </>
}