import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import { FullscreenGalleryProps } from "./types";
import "./FullscreenGallery.css";

const FullscreenGallery = ({
  imgs,
  currentImage,
  handleClose,
}: FullscreenGalleryProps) => {
  const [index, setIndex] = useState(0);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const _scroll = window.scrollY;

    if (currentImage === "") {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
      setScroll(_scroll);
      setIndex(imgs.indexOf(currentImage));
    }
  }, [currentImage, imgs]);

  const handleClick = (direction: "next" | "prev") => {
    if (direction === "prev") {
      if (index === 0) {
        setIndex(imgs.length - 1);
      } else {
        setIndex(index - 1);
      }
    } else if (direction === "next") {
      if (index === imgs.length - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }
  };

  const close = (e: BaseSyntheticEvent) => {
    const classList = e.target.classList;

    if (
      classList.contains("FullscreenGallery__container") ||
      classList.contains("FullscreenGallery__img__container") ||
      classList.contains("FullscreenGallery__img")
    ) {
      handleClose();
    }
  };

  return (
    <div
      className="FullscreenGallery__container"
      onClick={(e) => close(e)}
      style={{ top: scroll }}
    >
      <button
        className="FullscreenGallery__button"
        onClick={() => handleClick("prev")}
      >
        <ArrowLeft />
      </button>
      <div className="FullscreenGallery__img__container position-relative">
        <img
          src={imgs[index]}
          className="FullscreenGallery__img"
          alt={index.toString()}
        />
      </div>
      <button
        className="FullscreenGallery__button"
        onClick={() => handleClick("next")}
      >
        <ArrowRight />
      </button>
    </div>
  );
};

export default FullscreenGallery;
