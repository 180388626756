import React, {useEffect, useState} from "react";
import {makeRequest} from "../../../utils/makeRequest";
import {ApiVersion} from "../../../config/types";
import {GalleryTypes} from "../types";
import {GalleryCard} from "./GalleryCard/GalleryCard";
import getUrl from "../../../utils/getUrl";
import './GalleryList.css'

interface GalleryData {
    id: number,
    name: string,
    galleryType: GalleryTypes,
    poster: string,
    deleted: boolean
}

export const GalleryList = () => {
    const [normal, setNormal] = useState<GalleryData[]>([])
    const [vr, setVr] = useState<GalleryData[]>([])

    useEffect(() => {
       makeRequest('gallery/getAll', {method: "GET", Api: ApiVersion.v1}).then((response: {status: number, data: GalleryData[] }) => {
           const {data} = response

           const _vr: GalleryData[] = []
           const _normal: GalleryData[] = []

            data.forEach(it => {
                if(it.galleryType ===  GalleryTypes.vr) {
                    _vr.push(it)
                } else if(it.galleryType === GalleryTypes.normal) {
                    _normal.push(it)
                }
            })

           setNormal(_normal)
           setVr(_vr)

       }).catch(err => {

       })
    }, [])

    return <div className="GalleryList__container">
        {normal.map((it, id) => <GalleryCard id={`${id}`} galleryId={it.id} galleryName={it.name} poster={`${getUrl()}${it.poster}`} />)}
        {vr.map((it, id) => <GalleryCard id={`vr${id}`} galleryId={it.id} galleryName={it.name} poster={`${getUrl()}${it.poster}`} />)}
    </div>
}