import {Button, Form} from "react-bootstrap";
import {FormEvent, useRef} from "react";
import useAuth from "../../Hooks/useAuthenticate";

export const Login = () => {
    const {login} = useAuth()

    const loginInput = useRef<HTMLInputElement>()
    const password = useRef<HTMLInputElement>()

    const submit = (e: FormEvent) => {
        e.preventDefault()

        login(loginInput.current.value, password.current.value)
    }

    return <Form onSubmit={(e) => submit(e)}>
        <Form.Group>
            <Form.Label>Login</Form.Label>
            <Form.Control ref={loginInput} type="text" />
        </Form.Group>
        <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control ref={password} type="password" />
        </Form.Group>
        <Button type="submit" variant="primary">Login</Button>
    </Form>
}