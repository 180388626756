import { config } from '../config/'
import { ApiVersion } from '../config/types'
import getUrl from './getUrl'

const defaultOptions: RequestOptions = {
  method: 'GET',
  Api: config.apiVersion,
  headers: {
    'X-API-KEY': 'apikey',
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'Access-Control-Allow-Origin': '*'
  },
  mode: 'cors'
}

export async function makeRequest(
  url: string,
  options?: RequestOptions,
  data?: Record<string, unknown> | FormData
) {
  try {
    let opts = Object.assign(defaultOptions, options)

    if (data) {
      //const dataString = JSON.stringify(data)
      const dataString = Object.entries(data).map(([k,v])=>{return k+'='+v}).join('&')
      opts = Object.assign(opts, { body: dataString })
    }

    if (config.buildType === 'development') {
      console.log('makeRequest options', opts)
    }

    const urlString =
      opts.Api === ApiVersion.v1
        ? `${getUrl()}/api/v1/index.php/${url}`
        : `${getUrl()}/api/${url}`

    const response = await fetch(urlString, opts as any)

    if (config.buildType === 'development') {
      console.log('makeRequest response:', response)
    }

    const status = response.status
    try {
      const responseData = await response.clone().json()

      if (config.buildType === 'development') {
        console.log('makeRequest', { status, data: responseData })
      }

      return { status, data: responseData }
    } catch(e) {
      console.log(e)

      if(config.buildType === 'development') {
        console.error(await response.text())
      }

      return {status, data: {}}
    }
  } catch (error) {
    if (config.buildType === 'development') {
      console.log('makeRequest error:', error)
    }
    throw Error(error as string)
  }
}

export interface RequestOptions {
  method: 'POST' | 'GET'
  Api?: ApiVersion
  headers?: Record<string, unknown>
  mode?: 'cors'
}
