import React from "react";
import 'aframe'
import {Scene, Entity} from 'aframe-react'

export const VrImage = ({url}: {url: string}) => (
    // <div className="VR__image__container">
        <div className="card position-relative bg-dark ratio ratio-16x9">
            <Scene embedded>
                <Entity magicWindowTrackingEnabled={false} primitive='a-sky' src={url} />
            </Scene>
        </div>
    // </div>
)