export interface iConfig {
  buildType: BuildType;
  apiKey: string;
  apiVersion: ApiVersion;
}

export type BuildType = "development" | "production";
export enum ApiVersion {
  v0 = 0,
  v1 = 1,
}
