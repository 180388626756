import React from "react";
import "./Background.css";

const Background = () => {
  return (
    <div className="Background__bg">
      <div className="Background__backdrop"></div>
    </div>
  );
};

export default Background;
