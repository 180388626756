import React from "react";
import Background from "../Components/Background/Background";
import Footer from "../Components/Footer/Footer";
import GalleryComponent from "../Components/Gallery/Gallery";
import Menu from "../Components/Menu/Menu";
import { config } from "../config";
import "../styles/Home.css";
import {Development} from "../Components/Development/Development";
import {NewContactForm} from "../Components/ContactForm/NewContactForm";

function Home() {
  return (
    <>
      <div className="container-fluid bg-dark text-white main__container">
        <Background />
        <header>
          <Menu />
          <div className="Home__header__container" id="home">
            <div className="Home__header__anim__container">
              <div className="Home__header__logo">
                <h1>KOMPSERWIS</h1>
              </div>
              <div className="Home__header__spacer"></div>
              <div className="Home__header__info">
                <div>
                  tel: 731 786 660 <br />
                  e-mail: kompserwis.slawno@gmail.com
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>
          <section className="Home__section Home__about pb-2" id="about">
            <div className="Home__about__content">
              <article>
                <h2>O nas</h2>
                <p>
                  Jesteśmy młodą, dynamiczną firmą świadczącą usługi w branży IT
                  w Sławnie. Nasza szeroka oferta skierowana jest do klientów
                  indywidualnych oraz małych i średnich firm. Prowadzimy
                  skuteczny serwis laptopów, komputerów i konsol przy
                  wykorzystaniu nowoczesnego sprzętu. Posiadane doświadczenie
                  pozwala nam na świadczenie usług profesjonalnie i rzetelnie.
                  Jeżeli Twój laptop, albo komputer odmówił posłuszeństwa lub
                  działa za wolno to miejscem gdzie możesz go naprawić jest nasz
                  serwis (3 maja 2 76-100 Sławno – znajdziesz nas pod tym
                  adresem). Wiemy jak bardzo czas jest cenny, dlatego oferujemy
                  indywidualne i profesjonalne podejście, dzięki któremu szybko
                  rozwiążemy dany problem. Dajemy gwarancję na wykonywane
                  usługi, ponieważ jesteśmy solidni, wiarygodni, a nasze
                  długoletnie doświadczenie i wielu zadowolonych Klientów to
                  potwierdza. Wykonujemy zaawansowane naprawy w sytuacjach, w
                  których inne serwisy nie poradziły sobie. Warto wybrać właśnie
                  nasz serwis komputerowy. Sławno to miasto gdzie działa sporo
                  tego typu firm, jednak to co wyróżnia nas wśród konkurencji
                  to:
                </p>
                <ul className="Home__about__list">
                  <li>
                    <strong>Gwarancja bezpieczeństwa</strong> – gwarantujemy, że
                    dane znajdujące się na Państwa komputerach są bezpieczne.
                    Nasi informatycy nigdy nie usuwają danych bez wcześniejszej
                    wyraźnej zgody ich właściciela.
                  </li>
                  <li>
                    <strong>Gwarancja wysokiej jakości</strong> – posiadamy
                    potrzebną wiedzę jak również wieloletnie doświadczenie oraz
                    profesjonalny sprzęt serwisowy.
                  </li>
                  <li>
                    <strong>Gwarancja terminowości</strong> – z każdym klientem
                    ustalamy najkrótszy możliwy termin wykonania. Naprawę
                    rozpoczynamy natychmiast gdy komputer znajdzie się w
                    serwisie.
                  </li>
                </ul>
                <p>
                  Nasz serwis charakteryzuje nie tylko gwarancja rzetelnej
                  naprawy, ale również miła obsługa Klienta. Do każdego zlecenia
                  podchodzimy indywidualnie, łącząc naszą wiedzę, doświadczenie
                  oraz pasję. Zdając sobie sprawę z tego, jak ważny jest sprawny
                  sprzęt, dokładamy wszelkich starań, aby czas oczekiwania na
                  jego odbiór był możliwie jak najkrótszy. Komp Serwis to nie
                  tylko naprawa komputerów. Sławno jest miastem, w którym
                  zapewniamy również kompleksowe wsparcie informatyczne dla
                  firm. Zajmujemy się bieżącą obsługą informatyczną.
                </p>
              </article>
            </div>
          </section>
          {config.buildType === "development" ? (
            <section className="Home__section Home__news" id="news">
              <h2>News</h2>
            </section>
          ) : (
            <></>
          )}
          <section className="Home__gallery">
            <GalleryComponent isMainPage />
          </section>
          <section className="Home__section" id="contact">
            <div className="Home__contact__container" style={{overflow: 'hidden'}}>
              <NewContactForm />
            </div>
          </section>
        </main>
        <Footer />
        {config.buildType === 'development' ? <Development /> : <></>}
      </div>
    </>
  );
}

export default Home;
