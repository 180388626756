import {Button, Form, Modal, ModalProps} from "react-bootstrap";
import {iGallery} from "../../@types/apiDataTypes";
import {FormEvent, useRef} from "react";
import getUrl from "../../utils/getUrl";
import {config} from "../../config";

export const AddGalleryModal = (props: ModalProps & {onAdded: (gallery: Partial<iGallery>) => void}) => {
    const name = useRef<HTMLInputElement>()
    const type = useRef<HTMLSelectElement>()

    const submit = async (e: FormEvent) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('name', name.current.value)
        formData.append('type', type.current.value)

        //TODO: use fetch from utils
        const response = await fetch(`${getUrl()}/api/v1/index.php/gallery/addGallery`, {method: "POST", body: formData})
        const result = await response.json()
        if(config.buildType === 'development') {
            console.log({addGalleryResponse: result})
        }

        if(result && result > 0) {
            props.onAdded({id: result, name: name.current.value})
        }
    }

    return <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
               Add gallery
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <Form onSubmit={(e) => submit(e)}>
               <Form.Group>
                   <Form.Label>Name</Form.Label>
                   <Form.Control ref={name} type='text' />
               </Form.Group>
               <Form.Group>
                   <Form.Label>Type</Form.Label>
                   <Form.Select ref={type}>
                       <option value='vr'>VR</option>
                       <option value='normal'>Normal</option>
                   </Form.Select>
               </Form.Group>
               <Button type='submit' variant="primary">Add</Button>
           </Form>
        </Modal.Body>
    </Modal>
}