import React from "react";
import { ImageProps } from "./types";
import "./Image.css";

const Image = ({ imageUrl, imgClick, id }: ImageProps) => {
  return (
    <div className="col-md-12 Image__container">
      <div className="card ratio ratio-16x9 img-skeleton" id={`img-${id}`}>
        <img
          src={imageUrl}
          className="img-fluid d-none"
          onClick={() => imgClick(imageUrl)}
          alt=""
          onLoad={() => {
              setTimeout(() => {
                  const img = document.querySelector(`#img-${id}`)

                  if(img) {
                      img.classList.add('bg-dark')
                      img.querySelector('img')?.classList.remove('d-none')
                      img.classList.remove('ratio')
                      img.classList.remove('ratio-16x9')
                      img.classList.remove('img-skeleton')
                  }
              }, 5000)
          }}
        />
      </div>
    </div>
  );
};

export default Image;
