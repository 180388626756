import React from "react";
import MenuItem from "./MenuItem/MenuItem";
import menuItems from "./menu.json";
import "./Menu.css";
import logo from '../../images/logo.png'
import { config } from "../../config";

const Menu = () => {
  const [menuClassnames, setMenuClassnames] = React.useState(["Menu"]);
  const [mobile, setMobile] = React.useState(false);
  const [mobileMenuActive, setMobileMenuActive] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 25;
      if (isScrolled) {
        setMenuClassnames(["Menu Menu--sticky"]);
      } else {
        setMenuClassnames(["Menu"]);
      }
    };

    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [menuClassnames]);

  React.useEffect(() => {
    const handleResize = (): any => {
      const isMobile = window.screen.width <= 768;
      setMobile(isMobile);
    };

    window.onload = handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("resize", handleResize);
    };
  }, [mobile]);

  React.useEffect(() => {
    const scrollY = window.scrollY;

    const handleScroll = (e: any) => {
      window.scrollTo(0, scrollY);
      e.preventDefault();
    };

    if (mobileMenuActive) {
      document.addEventListener("scroll", handleScroll);
    } else {
      document.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [mobileMenuActive]);

  return (
    <div id="menu" className={menuClassnames.join(" ")}>
      <img src={logo} alt="Logo" className="logo" />
      {mobile ? (
        <>
          <button
            className="mobile-menu-button"
            onClick={() => setMobileMenuActive(!mobileMenuActive)}
          >
            <div
              className={
                mobileMenuActive
                  ? "hamburger-container hamburger--active"
                  : "hamburger-container"
              }
            >
              <div className="hamburger"></div>
            </div>
          </button>
          <div
            className={
              mobileMenuActive ? "mobileMenu mobileMenu--active" : "mobileMenu"
            }
          >
            <ul className="mobileMenu-list">
              {menuItems.map((item) =>
                config.buildType === "production" ? (
                  item.available ? (
                    <MenuItem
                      name={item.name}
                      hash={item.hash}
                      link={item.link}
                      mobile={true}
                      key={item.name}
                      handleClick={() => {
                        setMobileMenuActive(false);
                      }}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <MenuItem
                    name={item.name}
                    link={item.link}
                    hash={item.hash}
                    mobile={true}
                    key={item.name}
                    handleClick={() => {
                      setMobileMenuActive(false);
                    }}
                  />
                )
              )}
            </ul>
          </div>
        </>
      ) : (
        <ul className="menu-list">
          {menuItems.map((item) =>
            config.buildType === "production" ? (
              item.available ? (
                <MenuItem
                    hash={item.hash}
                  mobile={false}
                  name={item.name}
                  key={item.name}
                  link={item.link}
                  handleClick={() => {}}
                />
              ) : (
                <></>
              )
            ) : (
              <MenuItem
                  hash={item.hash}
                mobile={false}
                name={item.name}
                key={item.name}
                link={item.link}
                handleClick={() => {}}
              />
            )
          )}
        </ul>
      )}
    </div>
  );
};

export default Menu;
