import {createContext, ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {config} from "../config";
import {useNavigate} from "react-router-dom";
import getUrl from "../utils/getUrl";

interface iAuthContext {
    user?: User
    login: (login: string, password: string) => void
    logout: () => void
}

export interface User {
    id: number
    authenticated: boolean
}

const AuthContext = createContext<iAuthContext>({} as iAuthContext)

export function AuthProvider({children}: {children: ReactNode}) {
    const navigate = useNavigate()
    const [user, setUser] = useState<User>()

    useEffect(() => {
        const userFromStorage = sessionStorage.getItem("USER")

        if(config.buildType === 'development') {
            console.log({userFromStorage})
        }

        if(userFromStorage) {
            try {
                const parsed = JSON.parse(userFromStorage) as User
                setUser(parsed)
            }  catch (error) {
                if(config.buildType === 'development') {
                    console.log(error)
                    setUser(undefined)
                }
            }
        } else {
            setUser(undefined)
        }
    }, [])

    async function login(login: string, password: string) {
        //TODO: use makeRequest
        const formData = new FormData()
        formData.append('login', login)
        formData.append('passwd', password)
        const response = await fetch(`${getUrl()}/api/v1/index.php/user/login`, {method: "POST", body: formData})
        const logged = await response.json()

        if(config.buildType === 'development') {
            console.log({logged})
        }

        if(logged) {
            setUser({id: 0, authenticated: true})
            sessionStorage.setItem("USER", JSON.stringify({id: 0, authenticated: true}))
            navigate("/admin/index")
        }
    }

    function logout() {
        //TODO: Logout request
        sessionStorage.removeItem("USER")
        setUser(undefined)
    }

    const memoizedValue = useMemo(() => (
        {
            user,
            login,
            logout,
        }
    ), [user])

    return(<AuthContext.Provider value={memoizedValue} >{children}</AuthContext.Provider>)
}

export default function useAuth() {
    return useContext(AuthContext)
}