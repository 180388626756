import React from "react";
import "./Footer.css";

const Footer = () => (
    <footer>
      <div className="Footer__container">
        <p>kompserwis-slawno.pl &copy; 2021-2022</p>
        <p>Autorem witryny jest: <a href="https://krystian-ostrowski.com" rel="noreferrer" target="_blank">Krystian Ostrowski</a></p>
      </div>
    </footer>
);

export default Footer;
