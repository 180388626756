import React from "react";
import map from '../../images/map.png'
import './NewContact.css'

const divStyle = {
    height: "70%",
    maxHeight: "900px",
    // width: "700px",
    borderRadius: "25%",
    border: "5px solid rgba(0,0,0, 0.75)",
    transform: "rotate(45deg)",
    background: "rgba(0,0,0, .75)",
    // position: 'absolute' as 'absolute',
    aspectRatio: '1/1',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

export const NewContactForm = () => (
    <div className='Contact__container'>
        <div className="p-5 Contact__info">
            <h1>Komp Serwis - Karol Matczak</h1>
            <span>ul. 3 Maja 2</span><br />
            <span>76-100 Sławno</span><br />
            <span>tel. 731-786-660</span>
        </div>
        <div className="Contact__map">
            <img src={map} alt="map"  />
        </div>
    </div>
)