import React, {FC} from "react";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";

interface GalleryCardProps {
    galleryId: number,
    galleryName: string,
    poster: string,
    id: string
}

export const GalleryCard: FC<GalleryCardProps> = ({galleryId, galleryName, poster, id}) => (
    <Card className="img-skeleton text-white ratio ratio-16x9" id={`gallery-${id}`} style={{breakInside: "avoid"}}>
        <Card.Img style={{objectFit: "cover"}} className="d-none" src={poster} onLoad={() => {
            //setTimeout(() => {
                const gallery = document.querySelector(`#gallery-${id}`)

                if(gallery) {
                    gallery.classList.add('bg-dark')
                    gallery.querySelector('img')?.classList.remove('d-none')
                    gallery.classList.remove('img-skeleton')
                }
            //}, 5000)
        }} />
        <Link to={`/galeria/${galleryId}`} className="card-img-overlay text-white d-flex flex-column justify-content-end text-decoration-none" style={{backgroundColor: 'rgba(0,0,0,.55)'}}>
            <Card.Title>{galleryName}</Card.Title>
        </Link>
    </Card>
)