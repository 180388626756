import React from "react";
import Helmet from "react-helmet";
import "./App.css";
import {Route, Routes} from "react-router-dom";
import Home from "./views/Home";
import Gallery from "./views/Gallery";
import MessangerCustomerChat from "react-messenger-customer-chat";
import { config } from "./config";
import "bootstrap/dist/css/bootstrap.min.css";
import favicon from './images/favicon.png'
import {Development} from "./Components/Development/Development";
import {Error404} from "./views/404";
import {Login} from "./views/Admin/Login";
import {AdminMain} from "./views/Admin/AdminMain";

function App() {
  return (
    <div className="App">
      <Helmet
        htmlAttributes={{ lang: "pl" }}
        title="KOMPSERWIS"
        meta={[
          {
            name: "description",
            content:
              "Jesteśmy młodą, dynamiczną firmą świadczącą usługi w branży IT w Sławnie. Nasza szeroka oferta skierowana jest do klientów indywidualnych oraz małych i średnich firm. Prowadzimy skuteczny serwis laptopów, komputerów i konsol przy wykorzystaniu nowoczesnego sprzętu.",
          },
          { charSet: "utf-8" },
          { name: "theme-color", content: "#000000" },
        ]}
        link={[{ rel: "icon", type: "image/png", href: favicon }]}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/galeria" element={<Gallery />}>
            <Route path=":id" element={<Gallery />} />
        </Route>
        <Route path="/admin">
            <Route path="index" element={<AdminMain />} />
            <Route path="login" element={<Login />} />
        </Route>
        <Route path="*" element={<Error404 />}>404</Route>
      </Routes>
      {config.buildType === "production" ? (
        <MessangerCustomerChat
          pageId="100227875719239"
          appId="490986642697056"
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default App;
